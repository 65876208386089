<template>
  <v-app>
    <v-card
      style="background: #FFF; height: 140px; margin-right: -10px;"
      :elevation="3"
    >
      <div
        style="width: 100%; height: 100%"
        class="d-flex flex-column justify-center align-center"
      >
        <v-img
          contain
          src="@/assets/title.png"
          transition="scale-transition"
          style="max-height: 75px"
        />
        <div>
          <v-btn
            text
            color="primary"
            v-for="route in nav_routes"
            :key="route.name"
            @click="() => navigate(route.link)"
          >
            {{ route.name }}
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-main>
      <router-view />
    </v-main>
    <v-footer>
      <p style="text-align: center; width: 100%">
        {{ currentYear }} Double River Investments, LLC<br />
        <a style="font-size: 14px; color: gray" href="https://dev.doubleriver.com">Employee Access</a>
      </p>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    nav_routes: [
      { name: "Home", link: "/" },
      { name: "Alpha", link: "/alpha" },
      { name: "Impact", link: "/impact" },
      { name: "Team", link: "/team" },
    ],
  }),
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  },
  methods: {
    navigate(link) {
      if (this.$router.currentRoute.path != link) {
        this.$router.push(link, null, null);
      }
    },
  },
};
</script>

<style>
#app {
  background: #fbfcfc;
}

#container {
  max-width: 1300px;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 24px;
}

hr {
  border: 1px solid #00000021;
  width: 100%;
  margin-bottom: 12px;
}

p {
  font-family: "Lora", serif;
  color: #9e989a;
  letter-spacing: 0px;
  font-size: 1.25em;
}

h2 {
  font-family: "Lora", serif;
  letter-spacing: 0px;
  font-size: 1.5em;
  font-weight: 400;
  color: #555;
}
</style>
