import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VCard,{staticStyle:{"background":"#FFF","height":"140px","margin-right":"-10px"},attrs:{"elevation":3}},[_c('div',{staticClass:"d-flex flex-column justify-center align-center",staticStyle:{"width":"100%","height":"100%"}},[_c(VImg,{staticStyle:{"max-height":"75px"},attrs:{"contain":"","src":require("@/assets/title.png"),"transition":"scale-transition"}}),_c('div',_vm._l((_vm.nav_routes),function(route){return _c(VBtn,{key:route.name,attrs:{"text":"","color":"primary"},on:{"click":() => _vm.navigate(route.link)}},[_vm._v(" "+_vm._s(route.name)+" ")])}),1)],1)]),_c(VMain,[_c('router-view')],1),_c(VFooter,[_c('p',{staticStyle:{"text-align":"center","width":"100%"}},[_vm._v(" "+_vm._s(_vm.currentYear)+" Double River Investments, LLC"),_c('br'),_c('a',{staticStyle:{"font-size":"14px","color":"gray"},attrs:{"href":"https://dev.doubleriver.com"}},[_vm._v("Employee Access")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }