import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  // Pages are lazy-loaded
  {
    path: '/alpha',
    name: 'Alpha',
    component: function () {
      return import('../views/Alpha.vue')
    }
  },
  {
    path: '/impact',
    name: 'Impact',
    component: function () {
      return import('../views/Impact.vue')
    }
  },
  {
    path: '/team',
    name: 'Team',
    component: function () {
      return import('../views/Team.vue')
    }
  },
  {
    path: '*',
    name: 'Home',
    component: Home
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
