<template>
  <v-container
    id="container"
    class="d-flex flex-column justify-center align-center"
  >
    <br />
    <v-img id="big_logo" src="@/assets/big_logo.png" />
    <br />
    <hr />
    <br />
    <p>
      Double River Investments is a quantitatively-driven investment company. We
      operate throughout the global markets in search of alpha. We also do our
      part to improve our world and make impact/humanitarian investments in
      developing countries, helping local communities.
    </p>
  </v-container>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style scoped>
#big_logo {
  width: 80%;
}
</style>
